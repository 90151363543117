import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import { getTypeFromSector } from "~/helpers/rent-level-helpers.js";
var FILTER_DEFAULTS = {
  activeFilter: 0,
  constructionYearMin: "",
  constructionYearMax: "",
  areaMin: "",
  areaMax: "",
  movingInYearMin: "",
  movingInYearMax: "",
  usageTypesSelected: ["Etagebolig", "Rækkehus", "Fritliggende bolig", "Uoplyst"],
  radius: ""
};
export default {
  components: {},
  inheritAttrs: false,
  props: {
    sectors: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      "default": false
    },
    errorText: {
      type: String,
      "default": ""
    },
    showError: {
      type: Boolean,
      "default": false
    },
    distanceFilterEnabled: {
      type: Boolean,
      "default": true
    },
    sectorFilterEnabled: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      open: false,
      collapsed: true,
      activeFilter: FILTER_DEFAULTS.activeFilter,
      sectorModel: this.sectors.map(function (sector) {
        return {
          id: sector,
          selected: true
        };
      }),
      sectorFilters: [_objectSpread({
        sectorID: "RESIDENTIAL_FREE_RENT",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "SHOP",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "OFFICE",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "INDUSTRY",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "OTHER_BUSINESS",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "RESIDENTIAL_SMALL",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "RESIDENTIAL_RENTAL",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      }), _objectSpread({
        sectorID: "RESIDENTIAL_COST",
        constructionYearFilter: {
          min: FILTER_DEFAULTS.constructionYearMin,
          max: FILTER_DEFAULTS.constructionYearMax
        },
        movingInYearFilter: {
          min: FILTER_DEFAULTS.movingInYearMin,
          max: FILTER_DEFAULTS.movingInYearMax
        },
        areaFilter: {
          min: FILTER_DEFAULTS.areaMin,
          max: FILTER_DEFAULTS.areaMax
        },
        usageCodeFilter: {
          keywords: FILTER_DEFAULTS.usageTypesSelected
        }
      }, this.distanceFilterEnabled && {
        circle: {
          radius: FILTER_DEFAULTS.radius
        }
      })],
      inputValids: {
        RESIDENTIAL_FREE_RENT: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        SHOP: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        OFFICE: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        INDUSTRY: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        OTHER_BUSINESS: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        RESIDENTIAL_SMALL: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        RESIDENTIAL_RENTAL: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        },
        RESIDENTIAL_COST: {
          constructionYearMinValid: true,
          constructionYearMaxValid: true,
          areaMinValid: true,
          areaMaxValid: true,
          movingInYearMinValid: true,
          movingInYearMaxValid: true,
          radiusValid: true
        }
      }
    };
  },
  computed: {
    selectedSectors: function selectedSectors() {
      return this.sectorModel.filter(function (sector) {
        return sector.selected;
      }).map(function (sector) {
        return sector.id;
      });
    },
    selectedSectorFilters: function selectedSectorFilters() {
      var _this = this;
      return this.sectorFilters.filter(function (sectorFilter) {
        return _this.selectedSectors.includes(sectorFilter.sectorID);
      });
    },
    sectorsWithUsageType: function sectorsWithUsageType() {
      return this.selectedSectors.filter(function (s) {
        return s === "RESIDENTIAL_FREE_RENT" || s === "RESIDENTIAL_SMALL" || s === "RESIDENTIAL_RENTAL" || s === "RESIDENTIAL_COST";
      });
    },
    usageTypes: function usageTypes() {
      return ["Etagebolig", "Rækkehus", "Fritliggende bolig", "Uoplyst"];
    },
    constructionFilterValid: function constructionFilterValid() {
      var _this2 = this;
      return this.selectedSectors.every(function (s) {
        return _this2.inputValids[s].constructionYearMinValid && _this2.inputValids[s].constructionYearMaxValid;
      });
    },
    areaFilterValid: function areaFilterValid() {
      var _this3 = this;
      return this.selectedSectors.every(function (s) {
        return _this3.inputValids[s].areaMinValid && _this3.inputValids[s].areaMaxValid;
      });
    },
    movingInFilterValid: function movingInFilterValid() {
      var _this4 = this;
      return this.selectedSectors.every(function (s) {
        return _this4.inputValids[s].movingInYearMinValid && _this4.inputValids[s].movingInYearMaxValid;
      });
    },
    radiusFilterValid: function radiusFilterValid() {
      var _this5 = this;
      return this.selectedSectors.every(function (s) {
        return _this5.inputValids[s].radiusValid;
      });
    }
  },
  watch: {
    sectors: function sectors(val) {
      this.sectorModel = val.map(function (sector) {
        return {
          id: sector,
          selected: true
        };
      });
    }
  },
  mounted: function mounted() {
    this.$emit("init", this.getFiltersForSearch());
  },
  methods: {
    setActiveFilter: function setActiveFilter(id) {
      if (this.activeFilter === id) {
        this.activeFilter = 0;
      } else {
        this.activeFilter = id;
      }
    },
    toggleOpen: function toggleOpen() {
      this.open = !this.open;
    },
    resetFilters: function resetFilters() {
      var _this6 = this;
      this.sectorFilters.forEach(function (f) {
        f.constructionYearFilter.min = FILTER_DEFAULTS.constructionYearMin;
        f.constructionYearFilter.max = FILTER_DEFAULTS.constructionYearMax;
        f.movingInYearFilter.min = FILTER_DEFAULTS.movingInYearMin;
        f.movingInYearFilter.max = FILTER_DEFAULTS.movingInYearMax;
        f.areaFilter.min = FILTER_DEFAULTS.areaMin;
        f.areaFilter.max = FILTER_DEFAULTS.areaMax;
        f.usageCodeFilter.keywords = FILTER_DEFAULTS.usageTypesSelected;
        if (_this6.distanceFilterEnabled) {
          f.circle.radius = FILTER_DEFAULTS.radius;
        }
        _this6.sectorModel.forEach(function (s) {
          s.selected = true;
        });
      });
      Object.entries(this.inputValids).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        Object.entries(value).forEach(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            key2 = _ref4[0],
            value2 = _ref4[1];
          _this6.inputValids[key][key2] = true;
        });
      });
      this.applyFilters();
    },
    applyFilters: function applyFilters() {
      var filters = this.getFiltersForSearch();
      this.$emit("change", this.getFiltersForSearch());
      var amplitudeFilterPayload = this.getFiltersForAmplitudePayload(filters);
      this.$amplitude.setFilterPropertyRentalLevels(amplitudeFilterPayload);
    },
    getFiltersForSearch: function getFiltersForSearch() {
      var _this7 = this;
      var activeSectorFilters = this.selectedSectorFilters.filter(function (f) {
        return _this7.sectors.find(function (s) {
          return s === f.sectorID;
        });
      });
      var filtersValid = activeSectorFilters.every(function (f) {
        return _this7.inputValids[f.sectorID].constructionYearMinValid && _this7.inputValids[f.sectorID].constructionYearMaxValid && _this7.inputValids[f.sectorID].areaMinValid && _this7.inputValids[f.sectorID].areaMaxValid && _this7.inputValids[f.sectorID].movingInYearMinValid && _this7.inputValids[f.sectorID].movingInYearMaxValid && _this7.inputValids[f.sectorID].radiusValid;
      });
      if (filtersValid) {
        return activeSectorFilters.map(function (f) {
          return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
            sectorID: f.sectorID
          }, _this7.constrctuctionYearFilterChanged(f) && {
            constructionYearFilter: {
              min: f.constructionYearFilter.min ? parseInt(f.constructionYearFilter.min, 10) : null,
              max: f.constructionYearFilter.max ? parseInt(f.constructionYearFilter.max, 10) : null
            }
          }), _this7.movingInYearFilterChanged(f) && {
            movingInYearFilter: {
              min: f.movingInYearFilter.min ? parseInt(f.movingInYearFilter.min, 10) : null,
              max: f.movingInYearFilter.max ? parseInt(f.movingInYearFilter.max, 10) : null
            }
          }), _this7.areaFilterChanged(f) && {
            areaFilter: {
              min: f.areaFilter.min ? parseFloat(f.areaFilter.min, 10) : null,
              max: f.areaFilter.max ? parseFloat(f.areaFilter.max, 10) : null
            }
          }), _this7.usageCodeFilterChanged(f) && {
            usageCodeFilter: f.usageCodeFilter
          }), _this7.distanceFilterEnabled && _this7.distanceFilterChanged(f) && {
            circle: {
              radius: parseFloat(f.circle.radius)
            }
          });
        });
      } else {
        return [];
      }
    },
    getFiltersForAmplitudePayload: function getFiltersForAmplitudePayload(filters) {
      var SECTOR_ID_NAME = "sectorID";
      var filtersPayload = _toConsumableArray(new Set(filters.flatMap(function (filter) {
        return Object.keys(filter);
      }).filter(function (key) {
        return key != SECTOR_ID_NAME;
      })));
      var sectorFilterActive = this.sectorModel.some(function (sector) {
        return !sector.selected;
      });
      if (sectorFilterActive) {
        filtersPayload.push("sectorFilter");
      }
      return {
        Filters: filtersPayload
      };
    },
    radiusUpdate: function radiusUpdate(radius) {
      this.radius = radius;
    },
    usageTypesChange: function usageTypesChange(value) {
      this.usageTypesSelected = value;
    },
    scrollToDataFoundation: function scrollToDataFoundation() {
      document.getElementById("datafoundation").scrollIntoView({
        behavior: "smooth"
      });
    },
    formatLabel: function formatLabel(type) {
      var _this8 = this;
      var n = 0;
      this.selectedSectorFilters.forEach(function (f) {
        switch (type) {
          case "constructionYear":
            if (f.constructionYearFilter.min !== FILTER_DEFAULTS.constructionYearMin || f.constructionYearFilter.max !== FILTER_DEFAULTS.constructionYearMax) {
              n++;
            }
            break;
          case "area":
            if (f.areaFilter.min !== FILTER_DEFAULTS.areaMin || f.areaFilter.max !== FILTER_DEFAULTS.areaMax) {
              n++;
            }
            break;
          case "movingInYear":
            if (f.movingInYearFilter.min !== FILTER_DEFAULTS.movingInYearMin || f.movingInYearFilter.max !== FILTER_DEFAULTS.movingInYearMax) {
              n++;
            }
            break;
          case "usageCode":
            if (f.usageCodeFilter.keywords.length !== _this8.usageTypes.length) {
              n++;
            }
            break;
          case "radius":
            if (f.circle.radius !== FILTER_DEFAULTS.radius) {
              n++;
            }
            break;
          case "sector":
            if (_this8.sectorModel.filter(function (s) {
              return s.selected;
            }).length !== _this8.sectorModel.length) {
              n++;
            }
            break;
        }
      });
      var translationChange = this.$t("RENT_LEVELS_NEW_FILTERMENU_SUMMARY_CHANGE");
      var translationChanges = this.$t("RENT_LEVELS_NEW_FILTERMENU_SUMMARY_CHANGES");
      var translationAll = this.$t("RENT_LEVELS_NEW_FILTERMENU_SUMMARY_ALL");
      var translationNothingChosen = this.$t("RENT_LEVELS_NEW_FILTERMENU_SUMMARY_NOTHING_SELECTED");
      return n ? n > 1 ? "".concat(n, " ").concat(translationChanges) : "".concat(n, " ").concat(translationChange) : type === "usageCode" ? translationAll : translationNothingChosen;
    },
    getConstructionYearMin: function getConstructionYearMin(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).constructionYearFilter.min;
    },
    setConstructionYearMin: function setConstructionYearMin(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).constructionYearFilter.min = value;
    },
    getConstructionYearMax: function getConstructionYearMax(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).constructionYearFilter.max;
    },
    setConstructionYearMax: function setConstructionYearMax(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).constructionYearFilter.max = value;
    },
    getAreaMin: function getAreaMin(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).areaFilter.min;
    },
    setAreaMin: function setAreaMin(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).areaFilter.min = value;
    },
    getAreaMax: function getAreaMax(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).areaFilter.max;
    },
    setAreaMax: function setAreaMax(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).areaFilter.max = value;
    },
    getMovingInYearMin: function getMovingInYearMin(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).movingInYearFilter.min;
    },
    setMovingInYearMin: function setMovingInYearMin(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).movingInYearFilter.min = value;
    },
    getMovingInYearMax: function getMovingInYearMax(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).movingInYearFilter.max;
    },
    setMovingInYearMax: function setMovingInYearMax(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).movingInYearFilter.max = value;
    },
    getUsageTypesSelected: function getUsageTypesSelected(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).usageCodeFilter.keywords;
    },
    setUsageTypesSelected: function setUsageTypesSelected(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).usageCodeFilter.keywords = value;
    },
    getRadius: function getRadius(sector) {
      return this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).circle.radius;
    },
    setRadius: function setRadius(sector, value) {
      this.sectorFilters.find(function (f) {
        return f.sectorID === sector;
      }).circle.radius = value;
    },
    setSectorsSelected: function setSectorsSelected(event) {
      this.sectorModel = this.sectors.map(function (sector) {
        return {
          id: sector,
          selected: event.includes(sector)
        };
      });
    },
    validateNumber: function validateNumber(s) {
      return s == null || s.toString().length == 0 || s.toString().length > 0 && !/\D/.test(s.toString());
    },
    constrctuctionYearFilterChanged: function constrctuctionYearFilterChanged(sector) {
      var minChanged = sector.constructionYearFilter.min != FILTER_DEFAULTS.constructionYearMin;
      var maxChanged = sector.constructionYearFilter.max != FILTER_DEFAULTS.constructionYearMax;
      return minChanged || maxChanged;
    },
    areaFilterChanged: function areaFilterChanged(sector) {
      var minChanged = sector.areaFilter.min != FILTER_DEFAULTS.areaMin;
      var maxChanged = sector.areaFilter.max != FILTER_DEFAULTS.areaMax;
      return minChanged || maxChanged;
    },
    movingInYearFilterChanged: function movingInYearFilterChanged(sector) {
      var minChanged = sector.movingInYearFilter.min != FILTER_DEFAULTS.movingInYearMin;
      var maxChanged = sector.movingInYearFilter.max != FILTER_DEFAULTS.movingInYearMax;
      return minChanged || maxChanged;
    },
    distanceFilterChanged: function distanceFilterChanged(sector) {
      return sector.circle.radius != FILTER_DEFAULTS.radius;
    },
    usageCodeFilterChanged: function usageCodeFilterChanged(sector) {
      return sector.usageCodeFilter.keywords.length != FILTER_DEFAULTS.usageTypesSelected.length;
    },
    getTypeFromSector: getTypeFromSector
  }
};