// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/blur-background.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1UY6H{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;-webkit-clip-path:inset(0 0 0 0 round var(--border-radius-large));clip-path:inset(0 0 0 0 round var(--border-radius-large));display:flex;flex-direction:column;flex-grow:1;margin-left:1rem;margin-right:1rem;position:relative;width:calc(100% - 2rem)}.intro_hATvA{display:flex;margin:8rem auto;max-width:66%;z-index:1}.intro_hATvA.isMobile_ka0bD{margin:4rem 1rem;max-width:none}.alignRight_3m1ZL{display:flex;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1UY6H",
	"intro": "intro_hATvA",
	"isMobile": "isMobile_ka0bD",
	"alignRight": "alignRight_3m1ZL"
};
module.exports = ___CSS_LOADER_EXPORT___;
